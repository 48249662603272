<template>
  <div class="app-container dark-theme">
    <PrimeToast />
    
    <!-- Main content that's always rendered, but may be blurred -->
    <main class="p-4" :class="{ 'blur-content': !isAuthenticated }">
      <router-view />
    </main>
    <footer class="p-3 text-center" :class="{ 'blur-content': !isAuthenticated }">
      <p>Digitos search index</p>
    </footer>
    
    <!-- Access Code Overlay -->
    <div v-if="!isAuthenticated" class="access-overlay">
      <div class="access-modal">
        <img class="dark-logo" src="https://digitos.cz/assets/img/logo.svg" alt="DIGITOS" style="
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-bottom: 20px;
">

        <div class="input-container">
          <PrimeInputText 
            v-model="accessCode" 
            placeholder="Zadejte přístupový kód" 
            class="w-full text-center" 
            @keyup.enter="verifyAccessCode"
            :class="{ 'p-invalid': accessError }"
          />
          <small v-if="accessError" class="p-error">{{ accessError }}</small>
        </div>
        <PrimeButton 
          label="Přihlásit se" 
          @click="verifyAccessCode" 
          class="w-full mt-3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  data() {
    return {
      accessCode: '',
      accessError: '',
      isAuthenticated: false,
      validAccessCodes: ['tom', 'ondra', 'krystof', 'admin', '123789', 'jonas']
    }
  },
  created() {
    // Check if user is already authenticated
    const storedAccessCode = localStorage.getItem('accessCode')
    if (storedAccessCode) {
      this.isAuthenticated = true
    }
  },
  methods: {
    verifyAccessCode() {
      // Convert input to lowercase for case-insensitive comparison
      const normalizedCode = this.accessCode.trim().toLowerCase()
      
      if (this.validAccessCodes.includes(normalizedCode)) {
        // Store the code in localStorage
        localStorage.setItem('accessCode', normalizedCode)
        this.isAuthenticated = true
        this.accessError = ''

        // Log the access to backend
        this.logAccess(normalizedCode)
      } else {
        this.accessError = 'Neplatný přístupový kód'
      }
    },
    
    async logAccess(code) {
      try {
        await axios.post('https://api.index.digitos.cz/api/log/access', { accessCode: code })
      } catch (error) {
        console.error('Failed to log access:', error)
      }
    }
  }
}
</script>

<style>
:root {
  --accent-color: #2fd8b2;
  --accent-color-dark: #26b091;
  --accent-color-light: #4dffcc;
  --dark-bg: #121212;
  --dark-surface: #1e1e1e;
  --dark-card: #2d2d2d;
  --dark-border: #333333;
  --dark-text: #e0e0e0;
  --dark-text-secondary: #a0a0a0;
  --font-family: 'Oxanium', sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: var(--font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dark-theme {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

header {
  height: 60px;
  background-color: var(--accent-color) !important;
}

main {
  flex: 1;
}

footer {
  background-color: var(--dark-surface);
  border-top: 1px solid var(--dark-border);
  color: var(--dark-text-secondary);
}

.dark-theme .p-card {
  background-color: var(--dark-surface);
  color: var(--dark-text);
  border: 1px solid var(--dark-border);
}

.dark-theme .p-card .p-card-title {
  color: var(--dark-text);
}

.dark-theme .p-card .p-card-content {
  color: var(--dark-text);
}

.dark-theme .p-inputtext {
  background-color: var(--dark-card);
  color: var(--dark-text);
  border-color: var(--dark-border);
}

.dark-theme .p-inputtext:enabled:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 1px var(--accent-color-light);
}

.dark-theme .p-button {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.dark-theme .p-button:hover {
  background-color: var(--accent-color-dark);
  border-color: var(--accent-color-dark);
}

.dark-theme .p-button.p-button-outlined {
  background-color: transparent;
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.dark-theme .p-button.p-button-outlined:hover {
  background-color: rgba(47, 216, 178, 0.1);
}

.dark-theme .p-button.p-button-text {
  color: var(--accent-color);
  background-color: transparent;
}

.dark-theme .p-datatable {
  background-color: var(--dark-surface);
  color: var(--dark-text);
}

.dark-theme .p-datatable .p-datatable-header,
.dark-theme .p-datatable .p-datatable-thead > tr > th {
  background-color: var(--dark-card);
  color: var(--dark-text);
  border-color: var(--dark-border);
}

.dark-theme .p-datatable .p-datatable-tbody > tr {
  background-color: var(--dark-surface);
  color: var(--dark-text);
  border-color: var(--dark-border);
}

.dark-theme .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: var(--dark-card);
}

.dark-theme .p-datatable .p-datatable-tbody > tr:hover {
  background-color: rgba(47, 216, 178, 0.1);
}

.dark-theme .p-paginator {
  background-color: var(--dark-surface);
  color: var(--dark-text);
  border-color: var(--dark-border);
}

.dark-theme .p-paginator .p-paginator-element {
  color: var(--dark-text);
}

.dark-theme .p-paginator .p-paginator-element.p-highlight {
  background-color: var(--accent-color);
  color: #ffffff;
}

.dark-theme .field-label {
  color: var(--dark-text) !important;
}

.dark-theme .field-value {
  background-color: var(--dark-card) !important;
  color: var(--dark-text) !important;
}

/* Toast styles for dark theme */
.dark-theme .p-toast {
  opacity: 0.95;
}

.dark-theme .p-toast .p-toast-message {
  background-color: var(--dark-card);
  color: var(--dark-text);
  border-color: var(--dark-border);
}

.dark-theme .p-toast .p-toast-icon-close {
  color: var(--dark-text);
}

/* Access Overlay Styles */
.blur-content {
  filter: blur(6px);
  pointer-events: none; /* Prevents interaction when blurred */
  user-select: none; /* Prevents text selection when blurred */
}

.access-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.access-modal {
  background-color: var(--dark-surface);
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  border: 1px solid var(--dark-border);
  font-family: var(--font-family);
}

.input-container {
  margin-bottom: 1rem;
}

/* PrimeVue Overrides for Oxanium font */
.dark-theme .p-inputtext,
.dark-theme .p-button,
.dark-theme .p-datatable,
.dark-theme .p-card,
.dark-theme label,
.dark-theme h1, 
.dark-theme h2, 
.dark-theme h3, 
.dark-theme h4, 
.dark-theme h5,
.dark-theme p {
  font-family: var(--font-family);
}

.dark-theme .p-inputtext {
  letter-spacing: 0.01em;
}

.dark-theme .p-button {
  letter-spacing: 0.03em;
  font-weight: 500;
}
</style>
