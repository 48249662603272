<template>
  <div class="home">
    <PrimeCard class="mb-4">
      <template #title>Vyhledávání Uživatelů</template>
      <template #content>
        <div class="grid">
          <div class="col-12 md:col-6 lg:col-3 mb-2">
            <label for="firstname" class="block mb-1">Jméno</label>
            <PrimeInputText 
              id="firstname"
              v-model="searchParams.firstname" 
              placeholder="Jméno" 
              @keyup.enter="searchUsers" 
              class="w-full"
            />
          </div>
          <div class="col-12 md:col-6 lg:col-3 mb-2">
            <label for="surname" class="block mb-1">Příjmení</label>
            <PrimeInputText 
              id="surname"
              v-model="searchParams.surname" 
              placeholder="Příjmení"
              @keyup.enter="searchUsers" 
              class="w-full"
            />
          </div>
          <div class="col-12 md:col-6 lg:col-3 mb-2">
            <label for="facebook_id" class="block mb-1">Facebook ID</label>
            <PrimeInputText 
              id="facebook_id"
              v-model="searchParams.facebook_id" 
              placeholder="Facebook ID" 
              @keyup.enter="searchUsers" 
              class="w-full"
            />
          </div>
          <div class="col-12 md:col-6 lg:col-3 mb-2">
            <label for="phone_number" class="block mb-1">Telefonní číslo</label>
            <PrimeInputText 
              id="phone_number"
              v-model="searchParams.phone_number" 
              placeholder="Telefonní číslo" 
              @keyup.enter="searchUsers" 
              class="w-full"
            />
          </div>
          <div class="col-12 mb-2">
            <label for="general_search" class="block mb-1">Obecné vyhledávání</label>
            <div class="p-inputgroup">
              <PrimeInputText 
                id="general_search"
                v-model="searchParams.search" 
                placeholder="Hledání ve všech polích" 
                @keyup.enter="searchUsers" 
                class="w-full"
              />
              <PrimeButton icon="pi pi-search" @click="searchUsers" />
            </div>
            <small class="text-gray-500 mt-1 block">Zadejte maximálně dvě slova pro vyhledání konkrétní kombinace jména, příjmení, emailu, telefonu, pohlaví nebo adresy.</small>
          </div>
          <div class="col-12 flex justify-content-end">
            <PrimeButton 
              label="Vymazat" 
              class="p-button-outlined mr-2" 
              @click="clearSearch" 
              icon="pi pi-trash" 
            />
            <PrimeButton 
              label="Hledat" 
              @click="searchUsers" 
              icon="pi pi-search" 
            />
          </div>
        </div>
      </template>
    </PrimeCard>

    <PrimeCard class="results-card">
      <template #title >
        <div class="results-title" style="text-align:center">Výsledky [{{ formatNumber(totalUsers) }}]</div>
      </template>
      <template #content>
        <PrimeDataTable 
          :value="users" 
          :loading="loading" 
          stripedRows 
          v-model:selection="selectedUser"
          selectionMode="single"
          @row-click="onRowClick"
          class="p-datatable-sm"
          responsiveLayout="scroll"
        >
          <PrimeColumn field="firstname" header="Jméno" 
          style="padding-left:20px">
            <template #body="slotProps">
              {{ slotProps.data.firstname || '—' }}
            </template>
          </PrimeColumn>
          <PrimeColumn field="surname" header="Příjmení">
            <template #body="slotProps">
              {{ slotProps.data.surname || '—' }}
            </template>
          </PrimeColumn>
          <PrimeColumn field="phone_number" header="Telefonní Číslo">
            <template #body="slotProps">
              {{ slotProps.data.phone_number || '—' }}
            </template>
          </PrimeColumn>
          <PrimeColumn field="email" header="Email">
            <template #body="slotProps">
              {{ slotProps.data.email || '—' }}
            </template>
          </PrimeColumn>
          <PrimeColumn field="date_of_birth" header="Datum narození">
            <template #body="slotProps">
              {{ formatBirthDate(slotProps.data.date_of_birth) }}
            </template>
          </PrimeColumn>
          <PrimeColumn field="gender" header="Pohlaví">
            <template #body="slotProps">
              {{ slotProps.data.gender || '—' }}
            </template>
          </PrimeColumn>
          <PrimeColumn field="address" header="Adresa">
            <template #body="slotProps">
              {{ slotProps.data.address || '—' }}
            </template>
          </PrimeColumn>
          <PrimeColumn field="address2" header="Adresa 2">
            <template #body="slotProps">
              {{ slotProps.data.address2 || '—' }}
            </template>
          </PrimeColumn>
          <PrimeColumn header="Akce">
            <template #body="slotProps">
              <PrimeButton 
                icon="pi pi-eye" 
                class="p-button-sm p-button-text large-icon-btn" 
                @click="viewUserDetails(slotProps.data)"
                tooltip="Zobrazit Detail"
                
                style="margin-right: 5px;margin-left:5px;" 
              />
              <PrimeButton 
                v-if="slotProps.data.facebook_id"
                icon="pi pi-facebook" 
                class="p-button-sm p-button-text facebook-btn"
                style="margin-left: 5px;" 
                @click="openFacebookProfile(slotProps.data.facebook_id)"
              />
            </template>
          </PrimeColumn>
        </PrimeDataTable>

        <div class="flex justify-content-center mt-4">
          <PrimePaginator 
            v-if="totalUsers > 0"
            :rows="limit" 
            :totalRecords="totalUsers" 
            :first="(page - 1) * limit"
            @page="onPageChange($event)"
            :rowsPerPageOptions="[10, 20, 50, 100]"
            @rowsPerPageChange="onLimitChange($event)"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          />
        </div>
      </template>
    </PrimeCard>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/services/api'

export default {
  name: 'HomeView',
  setup() {
    const router = useRouter()
    const toast = useToast()
    
    const users = ref([])
    const totalUsers = ref(0)
    const loading = ref(false)
    const searchParams = reactive({
      search: '',
      firstname: '',
      surname: '',
      facebook_id: '',
      phone_number: '',
      date_of_birth: ''
    })
    const selectedUser = ref(null)
    const page = ref(1)
    const limit = ref(20)
    
    const searchUsers = async () => {
      try {
        loading.value = true
        const response = await ApiService.getUsers(searchParams, page.value, limit.value)
        users.value = response.data.data
        totalUsers.value = response.data.total
        page.value = response.data.page
        
        // Log the search activity
        await ApiService.logSearch({
          ...searchParams,
          page: page.value,
          limit: limit.value
        })
      } catch (error) {
        console.error('Error fetching users:', error)
        // Show error toast
        toast.add({ 
          severity: 'error', 
          summary: 'Chyba', 
          detail: 'Nepodařilo se načíst uživatele.', 
          life: 3000 
        })
      } finally {
        loading.value = false
      }
    }
    
    const clearSearch = () => {
      searchParams.search = ''
      searchParams.firstname = ''
      searchParams.surname = ''
      searchParams.facebook_id = ''
      searchParams.phone_number = ''
      searchParams.date_of_birth = ''
      page.value = 1
      searchUsers()
    }
    
    const formatDate = (dateString) => {
      if (!dateString) return '—'
      
      try {
        const date = new Date(dateString)
        const options = { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric' 
        }
        return date.toLocaleDateString('cs-CZ', options)
      } catch (error) {
        return dateString
      }
    }
    
    const formatBirthDate = (dateString) => {
      if (!dateString) return '—'
      
      // If it's already in DD/MM/YYYY format, return as is
      if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
        return dateString
      }
      
      try {
        const date = new Date(dateString)
        if (isNaN(date.getTime())) {
          // If not a valid date object, return the original string
          return dateString
        }
        
        const options = { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric' 
        }
        return date.toLocaleDateString('cs-CZ', options)
      } catch (e) {
        // Return the original string if there's an error
        return dateString
      }
    }
    
    const formatNumber = (num) => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    
    const viewUserDetails = (user) => {
      // Determine best field to use for URL (prefer facebook_id, fallback to phone_number then email)
      let field = 'facebook_id'
      let value = user.facebook_id
      
      if (!value) {
        field = 'phone_number'
        value = user.phone_number
      }
      
      if (!value) {
        field = 'email'
        value = user.email
      }
      
      if (!value) {
        toast.add({
          severity: 'error',
          summary: 'Chyba',
          detail: 'Nebyl nalezen žádný jedinečný identifikátor pro tohoto uživatele',
          life: 3000
        })
        return
      }
      
      router.push({ name: 'user-detail', params: { field, value } })
    }
    
    const openFacebookProfile = (facebookId) => {
      if (facebookId) {
        window.open(`https://www.facebook.com/${facebookId}`, '_blank')
      }
    }
    
    const onRowClick = (event) => {
      viewUserDetails(event.data)
    }
    
    const onPageChange = (event) => {
      page.value = event.page + 1
      searchUsers()
    }
    
    const onLimitChange = (newLimit) => {
      limit.value = newLimit
      page.value = 1 // Reset page when changing limit
      searchUsers()
    }
    
    onMounted(() => {
      searchUsers()
    })
    
    return {
      users,
      totalUsers,
      loading,
      searchParams,
      selectedUser,
      page,
      limit,
      searchUsers,
      clearSearch,
      formatDate,
      formatBirthDate,
      formatNumber,
      viewUserDetails,
      openFacebookProfile,
      onRowClick,
      onPageChange,
      onLimitChange
    }
  }
}
</script>

<style scoped>
/* Dark theme specific styles for HomeView */
.home {
  font-family: var(--font-family);
}

.home h2, 
.home h3, 
.home .p-card-title, 
.home .p-column-title {
  font-family: var(--font-family);
  letter-spacing: 0.01em;
}

.home .p-datatable .p-datatable-tbody > tr > td {
  font-family: var(--font-family);
  letter-spacing: 0.01em;
}

.home :deep(.field-label) {
  color: var(--dark-text-secondary);
}

.home :deep(.p-paginator) {
  margin-top: 1rem;
}

.home :deep(.p-datatable-header) {
  background-color: var(--dark-card);
  border-color: var(--dark-border);
}

.home :deep(.p-column-title) {
  color: var(--dark-text);
}

/* Hover effect with accent color */
.home :deep(.p-datatable tbody tr:hover) {
  background-color: rgba(47, 216, 178, 0.08) !important;
  cursor: pointer;
}

/* Selected row styling */
.home :deep(.p-datatable .p-datatable-tbody > tr.p-highlight) {
  background-color: rgba(47, 216, 178, 0.15) !important;
  color: var(--dark-text);
}

/* Search form labels */
.home label {
  color: var(--dark-text-secondary);
  font-weight: 500;
}

/* Helper text styling */
.home .text-gray-500 {
  color: var(--dark-text-secondary) !important;
}

/* Buttons with larger icons */
.large-icon-btn :deep(.p-button-icon),
.facebook-btn :deep(.p-button-icon) {
  font-size: 1.2rem; /* Increase icon size */
}

/* Results card styling */
.results-card :deep(.p-card-body) {
  padding: 0;
}

.results-card :deep(.p-card-content) {
  padding: 0;
}

.results-card :deep(.p-card-title) {
  padding: 1.2rem;
  margin-bottom: 0;
}
</style>
