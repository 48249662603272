import axios from 'axios'

// Create axios instance with interceptors to add access code header
const apiClient = axios.create({
  baseURL: 'https://api.index.digitos.cz/api',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

// Add request interceptor to include access code from localStorage
apiClient.interceptors.request.use(
  config => {
    const accessCode = localStorage.getItem('accessCode')
    if (accessCode) {
      config.headers['X-Access-Code'] = accessCode
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default {
  getUsers({ search = '', firstname = '', surname = '', facebook_id = '', phone_number = '', date_of_birth = '' } = {}, page = 1, limit = 20) {
    return apiClient.get('/users', {
      params: {
        search,
        firstname,
        surname,
        facebook_id,
        phone_number,
        date_of_birth,
        page,
        limit
      }
    })
  },

  getUserByField(field, value) {
    return apiClient.get(`/users/${field}/${value}`)
  },
  
  logSearch(searchParams) {
    return apiClient.post('/log/search', { searchParams })
  }
}
